<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },

    lightMode: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      category: this.value,
      openAll: false,
    };
  },
  methods: {
    clickOpenAll() {
      this.openAll = true;
    },

    calculateWidth() {
      const images = document.getElementsByClassName('image-only');
      const imagesArray = Array.from(images);

      imagesArray.forEach((i) => {
        const index = i.id.split("image-only-")[1];
        const text = document.getElementById('image-text-' + index);
        // eslint-disable-next-line no-param-reassign
        i.style.width = `${text.clientWidth + 100}px`;
      });
    },
  },
  mounted() {
    this.calculateWidth();
  },
  watch: {
      openAll() {
        setTimeout(() => {
          this.calculateWidth();
        }, 200);
},
  },
};
</script>

<template>
  <div class="breadcrumb-container">
    <div
        :class="[{ 'light': lightMode }, 'crumb', 'mobile-only']"
        v-for="(crumb, i) in category.ancestors"
        :key="i"
    >
      <div v-if="i == 0 || i == category.ancestors.length - 1 || openAll"  class="image-container">
          <!-- <img src="@/assets/long_arrow.png" class="image-only" :id="'image-only-' + crumb.id"> -->
          <div class="image-text" :id="'image-text-' + crumb.id">
            <localized-link
                :to="{ name:'library', params: { category: $i18n.locale == 'en' ? crumb.full_path_en.toLowerCase().split('/') : crumb.full_path_sv.toLowerCase().split('/') }  }">
              {{ $i18n.locale == 'en' ? crumb.title_en : crumb.title_sv }}
            </localized-link>
          </div>
        </div>
      <img @click="clickOpenAll()"  style="height:25px" v-if="(i !== 0 && i !== category.ancestors.length - 1) && !openAll" src="@/assets/chevronNew.png">
    </div>

    <div
        :class="[{ 'light': lightMode }, 'crumb', 'desktop-only']"
        v-for="(crumb, i) in category.ancestors"
        :key="i"
    >
      <div class="pre-arrow" v-if="i !== 0"></div>
      <localized-link
          :to="{ name:'library', params: { category: $i18n.locale == 'en' ? crumb.full_path_en.toLowerCase().split('/') : crumb.full_path_sv.toLowerCase().split('/') }  }">
        {{ $i18n.locale == 'en' ? crumb.title_en : crumb.title_sv }}
      </localized-link>
      <div class="arrow"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.image-container {
  position: relative;
  display: flex; /* Keeps the container size based on its content */

   &:after{
    content: "";
    display: inline-block;
    width: 12px;
   height: inherit;
    border-top: 12px solid transparent; /* Adjust size as needed */
    border-bottom: 12px solid transparent; /* Adjust size as needed */
    border-left: 12px solid #1c1c68;
  }

  &:before{
    content: "";
    display: inline-block;
    background-color: #1c1c68;
    width: 12px;
   height: inherit;
   border-top: 12px solid transparent; /* Adjust size as needed */
    border-bottom: 12px solid transparent; /* Adjust size as needed */
    border-left: 12px solid #fff;
  }

}

.image-only {
  height: 60px;
 
}

.image-text {
  //  background: url("../assets/long_arrow.png");
  background-color:#1c1c68;
  width: fit-content;
    background-repeat: no-repeat;
    padding: 4px;
    background-size: cover;
    // height: 40px;
  // position: absolute;
  // top: 21px; /* Adjust this value to vertically center the text within the image */
  // left: 50px;
  font-weight: 400; /* Adjust the font weight as needed */
  text-align: center; /* Center the text horizontally */
  color: #fff;
  
  word-break: break-all;
  white-space: nowrap;
}

.breadcrumb-container {
  text-align: left;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  

   @media screen and (max-width: $bp-mobile) {
      gap: 0.3em;
    }

  span {
    @media screen and (max-width: $bp-mobile) {
      display: none !important;
    }
  }

  .crumb {
    display: inline-flex;
    position: relative;

    &:nth-child(1){
      .image-container{
        &::before{
          border-left: 0;
          width: 4px;
        }
      }
    }

    .pre-arrow {
      position: absolute;
      left: 0px;
      height: 100%;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 10px solid #fff;
    }

    .arrow {
      position: absolute;
      right: -10px;
      height: 100%;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 10px solid #1c1c68;
    }

    &.light {
      a {
        display: inline-block;
        text-decoration: none;
        color: #1c1c68;
        font-weight: 700;
        background-color: #fff;
        padding: .5em 1.5em;
      }

      .pre-arrow {
        position: absolute;
        left: 0px;
        height: 100%;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 10px solid #1c1c68;
      }

      .arrow {
        position: absolute;
        right: -10px;
        height: 100%;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 10px solid #fff;
      }
    }
  }

  .desktop-only {
    margin-right: 1em;
    margin-bottom: 1em;
    a {
      display: inline-block;
      text-decoration: none;
      font-weight: normal;
      background-color: #1c1c68;
      padding: .5em 1.5em;
      color: #fff;
      font-size: 14px !important;
  line-height: 20px;
  font-weight: 400;
    }
  }
}
</style>
