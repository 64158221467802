import axios from 'axios';
import {i18n} from "vue-lang-router";

const sortRootByOrderSv = (list) =>
  list.sort((a, b) => {
    const at = +a.ancestors[0].order_sv;
    const bt = +b.ancestors[0].order_sv;
    return at > bt ? 1 : at < bt ? -1 : 0;
  });

const sortRootByTitle = (list) =>
  list.sort((a, b) => {
    const at = a.ancestors[0].titleSv.toUpperCase();
    const bt = b.ancestors[0].titleSv.toUpperCase();
    return at > bt ? 1 : at < bt ? -1 : 0;
  });

const sortByOrderSv = (list) =>
  list.sort((a, b) => {
    const at = +a.order_sv;
    const bt = +b.order_sv;
    return at > bt ? 1 : at < bt ? -1 : 0;
  });

const sortByOrderEn = (list) =>
    list.sort((a, b) => {
        const at = +a.order_en;
        const bt = +b.order_en;
        return at > bt ? 1 : at < bt ? -1 : 0;
    });

const sortByTitle = (list) =>
  list.sort((a, b) => {
      let at = "";
      let bt = "";
      if (a.title_sv != null) {
          at = a.title_sv.toUpperCase();
      }

      if (a.titleSv != null) {
          at = a.titleSv.toUpperCase();
      }

      if (b.title_sv != null) {
          bt = b.title_sv.toUpperCase();
      }

      if (b.titleSv != null) {
          bt = b.titleSv.toUpperCase();
      }

    return at > bt ? 1 : at < bt ? -1 : 0;
  });

/**
 * Compares to categories in the following order
 * Order_sv
 * Alphabetical
 * If any of these are equal, will try to get nearest child
 * and run the function recursively using the lvl param.
 */
const compare = (cat_a, cat_b, lvl = 0) => {
  const a = cat_a.ancestors[lvl];
  const b = cat_b.ancestors[lvl];

  // One of the lists are too short
  if (!b) return 1;
  if (!a) return -1;

  // Neither of the categories have sort-order, return alphabetical
  if (!a.order_sv && !b.order_sv) {
    const at = a.titleSv.toUpperCase();
    const bt = b.titleSv.toUpperCase();
    return at > bt ? 1 : at < bt ? -1 : compare(cat_a, cat_b, lvl + 1);
  }

  // One of the categories have sort order which is > alphabetical
  if (a.order_sv && !b.order_sv) return 1;
  if (!a.order_sv && b.order_sv) return -1;

  // Both of the categories are using sort-order
  const at = +a.order_sv;
  const bt = +b.order_sv;
  return at > bt ? 1 : at < bt ? -1 : compare(cat_a, cat_b, lvl + 1);
};

const translateLink = (link) => {
  const getTagsRgx = /(\{)(.+?)(\})/gm;
  // Get correct capture group
  const matches = [...link.matchAll(getTagsRgx)].map((m) => m[2].split('=')[1]);

  const removeTagsRgx = /(\{)(CatId=)(.+?)(\})/gim;
  link = link.replace(removeTagsRgx, '$3');

  return Promise.all(
    matches.map((m) => axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/translate/category/${m}`)),
  ).then((res) => {
    res
      .map((r) => r.data)
      .forEach((r) => {
        link = link.replace(r.ext_id, `<a href="/#/library/${r.id}">${r.web_title_sv}</a>`);
      });

    return link;
  });
};

const orderCategories = (list) => {
    let hasOrderSv = [];
    if (i18n.locale == 'en') {
         hasOrderSv = sortByOrderSv(list.filter((c) => c.order_sv != null));
    } else {
         hasOrderSv = sortByOrderEn(list.filter((c) => c.order_en != null));
    }
  const rest = sortByTitle(list.filter((c) => c.order_sv === null));

  return [...hasOrderSv, ...rest];
};

const orderBreadcrumbs = (list) => {
  const flatList = list.map((c) => {
    let cat = { ...c };
    delete cat.ancestors;
    c.ancestors.push(cat);
    return c;
  });

  flatList.sort(compare);

  return flatList;
};

export { translateLink, orderCategories, orderBreadcrumbs };
